import React from "react"
import FLayout from "../../../components/flayout";
import SignUpWealth from "../../../components/signUpWealth";

class Page extends React.Component {
    render() {
        return (
          <div className="v-100 d-flex flex-column">
          <FLayout showFooter={false} darkHeader={true} isFullPage={true} showSingleFooter={true} headerFixed={false} noSelBackground={true} seoFields={seoFields} showAnnouncement={true} loadTimesLandingPixel={true} showB2XTypeSelection={true} isActiveB2XTypeInvestors={false}>
            <SignUpWealth location={this.props.location}/>
          </FLayout>
          </div>
        )
    }
}

const seoFields = {
  description: "Start managing your investments in Stocks, Mutual Funds & other assets on web, iOS and Android devices.",
  // lang,
  // meta,
  // keywords,
  title: "Sign Up" 
}

export default Page