import React from "react"
import styles from "./styles.module.css";

class GoogleReviewsSlider extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            featuredReviews: this.props.reviewsList,
            defaultLength: 100,
            moadal: false,
            reviewContent: null
        }
    }

    componentDidMount() {
        // (function(d, s, id) { var js; if (d.getElementById(id)) {return;} js = d.createElement(s); js.id = id; js.src = "https://embedsocial.com/cdn/ht.js"; d.getElementsByTagName("head")[0].appendChild(js); }(document, "script", "EmbedSocialHashtagScript"));
        (function(d, s, id) { var js; if (d.getElementById(id)) {d.getElementById(id).parentNode.removeChild(d.getElementById(id));} js = d.createElement(s); js.id = id; js.src = "https://embedsocial.com/cdn/ht.js"; d.getElementsByTagName("head")[0].appendChild(js); }(document, "script", "EmbedSocialHashtagScript"));
    }

    render() {
        return (
            <>
                <div className={[styles.reviewsSec,'reviews-sec'].join(' ')}>
                    {/* <div class="embedsocial-hashtag" data-ref="e8f6f4cf92e965932f2fc4892e18798604006839" data-lazyload="yes"></div> */}
                    <div class="embedsocial-hashtag" data-ref="e8f6f4cf92e965932f2fc4892e18798604006839"></div>
                    {/* <div className="container">
                        <Slider {...settings}>
                            {
                                this.state.featuredReviews.map((review,i) => {
                                    return (
                                        <div className="col-md-12" key={i}>
                                            <ReviewItem review={review.node} readMore={this.readMoreContent} showDate={false}/>
                                        </div>
                                    )
                                })
                            
                            }
                        </Slider>
                    </div> */}
                    {/* <div className="text-center mt-5">
                        <Link to="/reviews" className='morebtn' href="#">See more reviews  <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    </div> */}
                </div>
            </>
        )
    }
}

export default GoogleReviewsSlider