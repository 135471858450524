import React from "react"

import SignUpWealthGraphic from "../../gatsby-images/signup-wealth-graphic";
import SignUpForm from "../signUpForm";

class SignUpWealth extends React.Component {
    constructor(props) {
        super(props);
    }

    handleUpdate = () => {

    }

    proceed = () => {

    }

    render() {
        return <SignUpForm formOnRight={false} Graphic={SignUpWealthGraphic} proceed={this.proceed} isWealth={true} location={this.props.location}
            headingText={`Manage your client investments with MProfit`}
            bodyText={`Maintain & track client portfolios across Stocks, Mutual Funds, Bonds, AIF & other assets. Provide a seamless reporting experience to your clients with MProfit's web & mobile apps.`}
        />
    }
}

export default SignUpWealth
